import {
  SET_USER_DETAILS,
  SET_USER_LOGOUT,
  UPDATE_USER_DETAILS,
  WATCHLIST_UPDATE_REQUEST,
  WATCHLIST_UPDATE_SUCCESS
} from '../types'
import { storeUser } from './helpers'

const storedUser =
  (typeof window !== 'undefined' && window.localStorage.getItem('user')) ||
  '{}'
const initialState = storedUser && JSON.parse(storedUser)

//  state = {videos:[]}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      // console.log(action.payload);
      storeUser({ ...state, ...action.payload, watchlistUpdate: false })
      return { ...state, ...action.payload }
    case WATCHLIST_UPDATE_REQUEST:
      return { ...state, watchlistUpdate: true }
    case WATCHLIST_UPDATE_SUCCESS:
      return { ...state, watchlistUpdate: false }
    case UPDATE_USER_DETAILS:
      // console.log(action.payload);
      const { key, value, to } = action.payload

      let _state = state
      if (to === 'ADD') {
        // const _key = state[key] || []
        _state = { ...state, [key]: [...(state[key] || []), value] }
      }

      if (to === 'REMOVE') {
        _state = {
          ...state,
          [key]: (state[key] || []).filter((id) => id !== value)
        }
      }
      storeUser({ ..._state, watchlistUpdate: false })
      return _state
    case SET_USER_LOGOUT:
      // window.localStorage.setItem('user', '{}')
      return {}
    default:
      return state
  }
}
