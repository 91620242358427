import { LOGIN } from '../redux/popup-types'

export default initializeListenner

const getUser = () => {
  let user = {}
  if (typeof window !== 'undefined') {
    const _user = window.localStorage.getItem('user')
    user = (_user && JSON.parse(_user)) || {}
  }
  return user
}

function initializeListenner (e, history, actions) {
  const { setPopup } = actions

  //
  e.cancelBubble = true
  e.stopPropagation()
  const icon = e?.target?.attributes

  const plusIcon = icon['data-plus-icon']
  const minsIcon = icon['data-minus-icon']

  //

  // if (infoIcon) {
  //   handleInfoIconClick(infoIcon, history);
  //   return;
  // }

  //

  //
  const user = getUser()

  if (plusIcon && !user.auth) {
    setPopup(LOGIN)
  }

  // if (plusIcon && user.auth) {
  //   if (!user.auth) {
  //     setPopup(LOGIN);
  //     return;
  //   }
  //   handleAddToWatchlist(plusIcon, actions);
  // } else if (minsIcon && user.auth) {
  //   if (!user.auth) {
  //     // setPopup('login');
  //     return;
  //   }
  //   handleRemoveFromWatchlist(minsIcon, actions);
  // }
}
export function handleInfoIconClick (e) {
  const history = useNavigate()

  const icon = e?.target?.attributes

  const infoIcon = icon && icon['data-info-icon']
  if (!infoIcon) return
  const { value } = infoIcon || {}

  if (value) {
    history(value)
  }
}

// function handleAddToWatchlist(plusIcon, actions) {
//   const { addToWatchlist } = actions;
//   const user = getUser();

//   const { value } = plusIcon || {};

//   if (value) {
//     const videoDetails = JSON.parse(value);
//     addToWatchlist(videoDetails, user);
//   }
// }

// function handleRemoveFromWatchlist(minsIcon, actions) {
//   const { removeFromWatchlist } = actions;
//   const user = getUser();

//   const { value } = minsIcon || {};

//   if (value) {
//     const videoDetails = JSON.parse(value);

//     removeFromWatchlist(videoDetails, user);
//   }
// }
