import {
  CLEAR_ERROR,
  SET_ERROR,
  SET_USER_DETAILS,
  SET_API_REQUEST,
  RESET_API_REQUEST,
  SET_USER_LOGOUT,
  UPDATE_USER_DETAILS,
  SET_POPUP,
} from '../types';
import axios from 'axios';
import { getToken } from '../../tools/tokens';
import config from '../../../../config';
import { NOTIFIER } from '../popup-types';
// import Cookies from 'js-cookie';

// export const setRemoveError = (dispatch) => {
//   dispatch({
//     type: REMOVE_ERROR,
//   });
// };

export const updateUserDetails = (dispatch, payload) => {
  dispatch({
    type: UPDATE_USER_DETAILS,
    payload,
  });
};

export const showNotifier = (dispatch, data) => {
  dispatch({
    type: SET_POPUP,
    payload: NOTIFIER,
    data,
  });
};

export const reset = (dispatch) => {
  clearError(dispatch);
  setRequest(dispatch);
};

export const resetRequest = (dispatch) => {
  dispatch({
    type: RESET_API_REQUEST,
    payload: { status: 'CALLING' },
  });
};

export const setLogout = (dispatch) => {
  dispatch({
    type: SET_USER_LOGOUT,
  });
};
export const setRequest = (dispatch, payload) => {
  dispatch({
    type: SET_API_REQUEST,
    payload,
  });
};

export const setUserDetails = (dispatch, details) => {
  dispatch({
    type: SET_USER_DETAILS,
    payload: details,
  });
};

export const clearError = (dispatch) => {
  
  dispatch({
    type: CLEAR_ERROR,
    payload: {},
  });
};

export const setError = (dispatch, error) => {
  dispatch({
    type: SET_ERROR,
    payload: error,
  });
};

export const generateId = (_) => {
  const s4 = (_) =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};

export const flushAllProviders = async () => {
  const { apiKey, internalName, apiProxy } = config;

  try {
    const token = await getToken();
    await axios({
      method: 'POST',
      url: `${apiProxy}/identity/tveSignout`,
      params: { site: internalName },
      headers: {
        'x-api-key': apiKey,
        Authorization: token,
      },
    });

    
  } catch (err) {
    
  }
};
