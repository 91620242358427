import React from 'react'
import './style.css'

export default function Skeleton ({ count = 10, gap }) {
  return (
    <div className='row-container'>
      <div className='skeleton title' />
      <div className='row' style={{ '--skelton-gap': gap }}>
        {Array.from({ length: count }, (_, i) => (
          <div className='videotile-container' key={i}>
            <div className='skeleton videotile' />
            <div className='skeleton videotile-label' />
          </div>
        ))}
      </div>
    </div>
  )
}

export function SearchSkeleton ({ count, gap }) {
  return (
    <div className='row-searchcontainer'>
      <div className='searchHeader'>
        <div className='skeleton searchtitle' />
        <div className='skeleton searchtitle' />
        <div className='skeleton searchtitle' />
      </div>
      <div className='row-search' style={{ '--skelton-gap': gap }}>
        {Array.from({ length: count }, (_, i) => (
          <div className='searchtile-container' key={i}>
            <div className='skeleton searchtile' />
            <div className='skeleton searchtile-label' />
          </div>
        ))}
      </div>
    </div>
  )
}
