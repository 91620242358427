// --------> startup point for client side

import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { loadableReady } from '@loadable/component'

import App from './App'
import './font.css'
import './client.css'
import './baseFiles/offline.html'
import createStore from './redux/store'
// import '~/client/services/firebase'
import ErrorBoundaries from './components/ErrorBoundaries'
import { setUserDetails } from './redux/actions/helpers'

const state = window.APP_STATE || {}
const mode = window.MODE
delete window.APP_STATE
window.lazy = true
delete window.MODE

const store = createStore(state)

if (typeof window !== 'undefined') {
  const storedUser =
    (typeof window !== 'undefined' && window.localStorage.getItem('user')) ||
    '{}'

  const userDetails = storedUser && JSON.parse(storedUser)

  setUserDetails(store.dispatch, userDetails)
}

// check if browser requires a polyfill.

const _window = ['fetch', 'Intl', 'Map'].every((el) => el in window)
const _string = ['startsWith', 'endsWith', 'includes'].every(
  (el) => el in String.prototype
)
const _object = ['assign', 'entries', 'keys'].every((el) => el in Object)
const _nodeList = 'forEach' in NodeList.prototype

loadableReady(() => {
  const render = () => {
    hydrateRoot(
      document.getElementById('root'),
      <ErrorBoundaries>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </ErrorBoundaries>
    )
  }

  if (_window && _string && _object && _nodeList) {
    render()
  } else {
    import('@babel/polyfill').then(render)
  }
})
