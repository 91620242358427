import axios from 'axios'
import config from '../../../config'
import { getToken } from './tokens'

export const initFbEvent = () => {
  const pixelId = '1994867780800278'
  if (pixelId) {
    ;(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    )
    // Insert your pixel ID here.
    window.fbq('init', pixelId)
  }
}

export const pixelEvents = {
  ADD_TO_CART: 'AddToCart', // done
  PAGE_VIEW: 'PageView', // done
  SUBSCRIPTION_INITIATED: 'SubscriptionInitiated', // done
  SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted' // done
}

export const FB_EVENTS = {
  PAGE_VIEW: 'PageView', // done
  ADD_TO_CART: 'AddToCart', // done
  SUBSCRIPTION_INITIATED: 'SubscriptionInitiated', // done
  SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted', // done
  SUBSCRIPTION_FAILED: 'SubscriptionFailed', // done
  REMOVED_FROM_WATCHLIST: 'RemovedFromWatchlist',
  SEARCH: 'Search', // done
  LOGIN: 'Login', // done
  LOGOUT: 'Logout', // done
  PLAY_STARTED: 'Play Started',
  WATCHED: 'Watched'
}

export const trackFbPixel = (eventName, payload = {}) => {
  window.fbq('track', eventName, payload)
}

export const trackFbEvent = async (eventName, payload = {}) => {
  const { internalName } = config

  // Will be replaced with actual url
  if (true) {
    const url = 'https://tracking.viewlift.com/conversion-api'
    const token = await getToken()
    axios({
      url,
      method: 'POST',
      params: { site: internalName, platform: 'web' },
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      data: {
        eventName,
        payload
      }
    })
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  }
}
