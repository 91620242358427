import React, { useState, useEffect, memo } from 'react'
import closeIcon from '../../assets/images/crossWhite.svg'
// import hoichoi_logo from '../../assets/images/hoichoi_logo.png'
import './style.css'
import Assetlogo from '../../assets/images/Assetlogo.svg'
import { checkIfDeviceIsIos } from '~/client/utils/findDevices'
const Index = () => {
  const [open, setOpen] = useState(false)
  const deviceState = useState('')
  const setDevice = deviceState[1]

  const deepLink = () => {
    window.open('https://b74k9.app.goo.gl/NPga', 'WindowName', 'noopener')
  }

  useEffect(() => {
    const deviceIos = checkIfDeviceIsIos()
    if (deviceIos) setDevice('ios')
    else setDevice('other')
    const installerBanner = sessionStorage.getItem('installerBanner')
    setTimeout(() => {
      if (installerBanner == 'false') {
        setOpen(false)
      } else {
        setOpen(true)
      }
    }, 5000)
  }, [])

  return (
    <div className="app-banner" style={!open ? { display: 'none' } : {}}>
      <div className="banner-content">
        <div className="app-icon">
          <img src={Assetlogo} alt="App Icon" />
        </div>
        <div className="app-message">
          <p onClick={deepLink}> Download our app for a better experience</p>
        </div>
      </div>
      <div className="close-icon">
        <button type="submit" onClick={deepLink}>
          DOWNLOAD
        </button>
        <img
          src={closeIcon}
          className="close-icon-img"
          alt="Close Icon"
          onClick={() => {
            sessionStorage.setItem('installerBanner', false)
            setOpen(false)
          }}
        />
      </div>
    </div>
  )
}

export default memo(Index)
