import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export const MetaTags = ({ contentData }) => {
  const {
    title,
    description,
    keywords,
    type,
    image = 'https://appcmsprod.viewlift.com/7fa0ea9a-9799-4417-99f5-cbb5343c551d/images/generated/placeholder-poster.png'
  } = contentData.metadataMap || {};
  const _keywords =
    'hoichoi, hoichoi originals, hoichoi tv, hoichoi app, svf, svf entertainment, bengali movies, watch bengali movies online, bengali webseries, watch bengali webseries online, webseries, hoichoi exclusive, world premiere, world digital premiere, webisode';

  const WHITELISTED_MODULES = {
    ShowDetailModule: true,
    VideoDetailModule: true
  };

  const [selectedContent, setSelectedContent] = useState({});
  const [uploadDate, setUploadDate] = useState('""');

  const contentPath = `https://${contentData.domainName}${contentData.path}`;

  const fetchContentGist = () => {
    if (contentData && contentData.modules && contentData.modules.length > 0) {
      const filteredModules = [...contentData.modules].filter(
        ({ moduleType = null }) => {
          return moduleType && WHITELISTED_MODULES[moduleType];
        }
      );
      return filteredModules;
    } else {
      return [];
    }
  };

  useEffect(() => {
    let contentGist = fetchContentGist();
    try {
      contentGist = contentGist[0].contentData[0].gist || {};
    } catch (e) {
      contentGist = {};
    }
    setSelectedContent(contentGist);
  }, []);

  useEffect(() => {
    if (selectedContent.publishDate) {
      const timestamp = selectedContent.publishDate;
      const date = new Date(timestamp);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const formattedDate = `"${year}-${month}-${day}"`;
      setUploadDate(formattedDate);
    }
  }, [selectedContent]);

  const metaName = `"${selectedContent.title || title}"`;
  const metaDesctiption = `"${selectedContent.description || description}"`;

  const metaContentUrl = `"${contentPath}"`;
  const metaUrl = `"${
    selectedContent && selectedContent.imageGist
      ? Object.values(selectedContent.imageGist)
          .filter((value) => value !== null)
          .join(',')
      : ['']
  }"`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords || _keywords} />
      {/* ${o.siteMeta && o.siteMeta.metaTitle ? o.siteMeta.metaTitle : ""} */}
      <meta property="og:type" content={type || 'website'} />
      {/* <meta property="og:url" content="${`https://` + o.host + o.path}"> */}
      {/* to disable zoom feature of inout fields of ios devices */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
      />
      <meta property="og:title" content={title} />
      <link rel="canonical" href={contentPath} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="hoichoi" />
      <meta property="og:image" content={image} />
      {/* Schema */}
      {contentData.location === '/' ? (
        <script type="application/ld+json">
          {`{
          "@context": "http://schema.org",
          "@type": "WebSite",
          "name": ${metaName},
          "url": ${metaContentUrl},
          "description": ${metaDesctiption},
          "publisher": {
            "@type": "Organization",
            "name": "Hoichoi",
            "url": ${metaContentUrl},
            "logo": "https://legacy.asset.viewlift.com/7fa0ea9a-9799-4417-99f5-cbb5343c551d/images/2024/07/15/1721036413210_hoichoilogo_140x40_dih4zunhimages-tab.webp?impolicy=resize&w=140&h=40"
          },
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": ${metaContentUrl}
          }
        }`}
        </script>
      ) : (
        <script type="application/ld+json" id="json-ld">
          {`{
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": ${metaName},
        "description": ${metaDesctiption},
        "thumbnailUrl": ${metaUrl},
        "contentUrl": ${metaContentUrl},
        "uploadDate": ${uploadDate},
        "interactionStatistic": {
          "@type": "InteractionCounter",
          "interactionType": { "@type": "http://schema.org/WatchAction" },
          "userInteractionCount": 5647018
        },
        "regionsAllowed": "IN,BN"
      }`}
        </script>
      )}
    </Helmet>
  );
};
