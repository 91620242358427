import { combineReducers } from 'redux'

import pageReducer from './pageReducer'
import userReducer from './userReducer'
import requestReducer from './requestReducer'
import popupsReducer from './popupsReducer'
import pageSettingsReducer from './pageSettingsReducer'
import nextVideoReducer from './nextVideoReducer'
import graminUserReducer from './grminUserReducer'
export default combineReducers({
  page: pageReducer,
  popups: popupsReducer,
  request: requestReducer,
  user: userReducer,
  pageSettings: pageSettingsReducer,
  nextVideo: nextVideoReducer,
  graminUser: graminUserReducer
})
