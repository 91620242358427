export const LOGIN = 'login';
export const MENU = 'menu';
export const SEARCH = 'search';
export const THANKYOU = 'thankyou';
export const CANCELPLN = 'cancelplan';
export const CHANGEPWD = 'changepassword';
export const FORGOTPWD = 'forgotpassword';
export const CONFIRMPWD = 'confirmpassword';
export const ACTIVEDVC = 'activedevice';
export const REEDEEM = 'redeem';
export const REFER = 'refer';
export const OTPPIN = 'otppin';
export const SHARE = 'share';
export const NOTIFIER = 'notifier';
export const VIDEOPLAYER = 'videoplayer';
