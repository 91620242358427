import axios from 'axios';
import config from '../../../../config';

import {
  SET_PAGE_SETTINGS,
} from '../types';

export const getPageSettingsData = () => async (dispatch) => {

  const { apiKey, siteConfigUrl } = config;
  try {
    const configUrl = await axios({
      url: siteConfigUrl,
      method: 'GET',
      headers: {
        'x-api-key': apiKey,
      },
    });

    const webJson = await axios({
      url: configUrl.data.Web,
      method: 'GET',
      headers: {
        'x-api-key': apiKey,
      },
    });

    dispatch({
      type: SET_PAGE_SETTINGS,
      payload: { ...configUrl.data, ...webJson.data },
    });
  } catch (e) {

  }
};
