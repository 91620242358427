import { SET_POPUP, PARENTAL_PIN_ACCESS } from '../types'

export default (state = {}, action) => {
  if (action.type === SET_POPUP) {
    const { payload, data, searchQuery } = action
    return {
      ...state,
      [payload]: data || !state[payload],
      searchQuery
    }
  }
  if (action.type === PARENTAL_PIN_ACCESS) {
    return {
      ...state,
      parentalPinAccess: action.payload
    }
  }
  return state
}
