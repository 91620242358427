import React from 'react';
import '~/client/components/Icons/styles.css';

export default function CrossIcon() {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
      <g
        id="dark-mode"
        stroke="none"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="M-5-Search-Typing"
          transform="translate(-330.000000, -163.000000)"
          stroke="#B2B2B4"
          strokeWidth="2"
        >
          <g id="Group" transform="translate(15.000000, 132.000000)">
            <g id="Group-10" transform="translate(0.000000, 32.000000)">
              <g id="x" transform="translate(316.000000, 0.000000)">
                <line x1={14} y1={0} x2={0} y2={14} />
                <line x1={0} y1={0} x2={14} y2={14} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
