import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetPassword } from '~/client/apis/user'
import { connect } from 'react-redux'
import './style.css'
import jwtDecode from 'jwt-decode'
import Loader from '../Loader'

function ResetPassword ({ lang }) {
  const [confirmPassword, setConfirmPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [alert, setAlert] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [resetToken, setResetToken] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [buttonLabel, setButtonLabel] = useState()
  const location = useLocation()
  const navigate = useNavigate()

  const clickHandler = (e) => {
    e.preventDefault()
    setButtonLabel(lang === 'bn' ? 'জমা দেওয়া হচ্ছে.....' : 'Submitting...')
    if (confirmPassword !== newPassword) {
      setErrorMessage('Confirm Password does not match with New Password')
    } else {
      resetPassword({ resetToken, newPassword })
        .then((res) => {
          setSuccess(true)
          setTimeout(() => {
            navigate('/')
          }, 3000)
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.error)
        })
    }
  }

  useEffect(() => {
    setButtonLabel(lang === 'bn' ? 'সাবমিট' : 'Submit')
  }, [])

  const passwordChangeHandler = (e) => {
    setButtonLabel(lang === 'bn' ? 'সাবমিট' : 'Submit')
    if (e.target.value.length < 4) {
      setErrorMessage('Password must be at least 5 characters long')
    }
    setNewPassword(e.currentTarget.value)
    setErrorMessage('')
  }

  const confirmPasswordChangeHandler = (e) => {
    setButtonLabel(lang === 'bn' ? 'সাবমিট' : 'Submit')
    if (e.target.value.length < 4) {
      setErrorMessage('Confirm Password must be at least 5 characters long')
    }
    setConfirmPassword(e.currentTarget.value)
    setErrorMessage('')
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('resetToken')
    setResetToken(token)
    if (token) {
      const decodedToken = jwtDecode(token)
      if (decodedToken) {
        // Check the expiration
        const now = Date.now() / 1000
        if (decodedToken.exp && decodedToken.exp < now) {
          setAlert(true)
          setIsLoading(false)
          setTimeout(() => {
            navigate('/')
          }, 5000)
        } else {
          setAlert(false)
          setIsLoading(false)
        }
      }
    }
  }, [location.search])

  return (
    <div className='reset-password-container'>
      {/* loader */}
      {isLoading ? <Loader center /> : null}
      {/* token alert */}
      {alert ? (
        <p>
          {lang === 'bn'
            ? 'টোকেনের মেয়াদ শেষ। অনুগ্রহ করে আবার ফরগেট পাসওয়ার্ড-এ ক্লিক করুন।'
            : 'The token is expired. Please click on forgot password again.'}
        </p>
      ) : (
        <>
          {/* success alert */}
          {success ? (
            <span>
              {lang === 'bn'
                ? 'আপনার পাসওয়ার্ড সফলভাবে পুনরায় সেট করা হয়েছে। আপনার নতুন পাসওয়ার্ড দিয়ে লগ ইন করুন।'
                : 'Your password has been reset successfully. Please login with your new password.'}
            </span>
          ) : (
            <>
              <div className='header'>
                {lang === 'bn' ? 'রিসেট পাসওয়ার্ড' : 'Reset Password'}
              </div>
              <div className='reset-password-form-container'>
                <form
                  action='/'
                  className='reset-password-form'
                  onSubmit={clickHandler}
                >
                  <div className='reset-password-form-item'>
                    <label
                      htmlFor='reset-password-mob-email'
                      className='reset-password-label'
                    >
                      {lang === 'bn' ? 'নিউ পাসওয়ার্ড' : 'New Password'}
                    </label>
                    <input
                      type='password'
                      id='reset-password-mob-email'
                      name='mob-email'
                      className='reset-password-form-input'
                      onChange={passwordChangeHandler}
                    />
                  </div>

                  <div className='reset-password-form-item'>
                    <label
                      htmlFor='reset-password-password'
                      className='reset-password-label'
                    >
                      {lang === 'bn'
                        ? 'কন্ফার্ম পাসওয়ার্ড'
                        : 'Confirm Password'}
                    </label>
                    <input
                      type='password'
                      id='reset-password-password'
                      name='password'
                      className='reset-password-form-input'
                      onChange={confirmPasswordChangeHandler}
                    />
                  </div>

                  {errorMessage && (
                    <p className='error-message-mark85'>{errorMessage}</p>
                  )}
                  <div className='reset-password-form-item'>
                    <button
                      className='reset-password-submit-button reset-password-block-button'
                      disabled={
                        newPassword.length < 4 || confirmPassword.length < 4
                      }
                      // onClick={clickHandler}
                    >
                      {buttonLabel}
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default connect((state) => {
  return {
    user: state.user,
    lang: state.page?.lang
  }
}, {})(ResetPassword)
