// export const removeDuplicate = (array) => {
//   if (result?.length) return array;
//   const result = [];
//   const idObject = {};
//   for (const each of array) {
//     if (idObject.hasOwn(each.id) === false) {
//       idObject[each.id] = each.id;
//       result.push(each);
//     }
//   }
//   return result;
// };

export const removeDuplicate = (array, key = 'id') => {
  console.log('here')
  const seen = new Set()
  return array.filter((item) => {
    const keyValue = item[key]
    if (seen.has(keyValue)) {
      return false
    } else {
      seen.add(keyValue)
      return true
    }
  })
}

export const getVerticalScrollPercentage = () => {
  const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'
  const scroll = isCSS1Compat
    ? document.documentElement.scrollTop
    : document.body.scrollTop
  const scrollHeight =
    document.documentElement.scrollHeight || document.body.scrollHeight

  if (scrollHeight - document.documentElement.clientHeight <= 0) return 100

  const value =
    (scroll / (scrollHeight - document.documentElement.clientHeight)) * 100

  return value
}

export const getCollectedVideoDRMType = (object) => {
  let clonedObj
  if (structuredClone) {
    clonedObj = structuredClone?.(object)
  } else {
    clonedObj = JSON.parse(JSON.stringify(object))
  }

  if (clonedObj?.type) delete clonedObj.type

  return Object.keys(clonedObj)?.join(',') || ''
}
