import { SET_POPUP, REMOVE_ERROR, PARENTAL_PIN_ACCESS } from '../types'

export const setPopup = (popup, data, searchQuery) => (dispatch) => {
  dispatch({ type: REMOVE_ERROR, payload: { err: null } })
  dispatch({
    type: SET_POPUP,
    payload: popup,
    data,
    searchQuery
  })
}

export const parentalAccess = (data) => (dispatch) => {
  dispatch({ type: PARENTAL_PIN_ACCESS, payload: data })
}
