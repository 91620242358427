// const path = require('path');

const env = 'prod'
// const env = 'staging';
const client =
  env === 'staging'
    ? {
        id: '57e4b76f-6168-41af-bdd8-c76a2e5bf798',
        domainName: 'staging-hoichoitv.viewlift.com',
        apiKey: 'BkSBbok02k6RYUlCLRzI23wac0euoSfC3FP7uW2S',
        internalName: 'staging-hoichoitv',
        getSocialAppId: 'g8y6exb8dn5',
        beaconSettings: {
          apiBaseUrl:
            'https://y3702sf2ai.execute-api.us-east-1.amazonaws.com/Beacon/firehouse-proxy',
          clientId: '57e4b76f-6168-41af-bdd8-c76a2e5bf798',
          siteName: 'staging-hoichoitc'
        },
        analytics_beacon: {
          enable: true,
          interval: 30,
          bufferInterval: 15,
          enableQOS: true
        },
        JUSPAY_SDK_PATH: 'https://sandbox.juspay.in/txns'
      }
    : {
        id: '7fa0ea9a-9799-4417-99f5-cbb5343c551d',
        domainName: 'www.hoichoi.tv',
        apiKey: 'PBSooUe91s7RNRKnXTmQG7z3gwD2aDTA6TlJp6ef',
        internalName: 'hoichoitv',
        getSocialAppId: 'g8y6exb8dn5',
        beaconSettings: {
          apiBaseUrl:
            'https://1a7ahu122g.execute-api.us-east-1.amazonaws.com/production_beacon/firehouse-proxy',
          clientId: '7fa0ea9a-9799-4417-99f5-cbb5343c551d',
          siteName: 'Hoichoi - Movies | Originals',
          enableQOS: true
        },
        analytics_beacon: {
          enable: true,
          interval: 30,
          bufferInterval: 15,
          enableQOS: true
        },
        JUSPAY_SDK_PATH: 'https://api.juspay.in/pay-v3.js'
      }

const {
  id,
  domainName,
  apiKey,
  internalName,
  getSocialAppId,
  beaconSettings,
  analytics_beacon,
  JUSPAY_SDK_PATH
} = client

module.exports = {
  id,
  apiKey,
  internalName,
  beaconSettings,
  analytics_beacon,
  JUSPAY_SDK_PATH,
  static: 'public',
  getSocialAppId,
  apiProxy: `https://${env}-api.viewlift.com`,
  layoutUrlBase: `https://appcms${env}.viewlift.com/`,
  siteConfigUrl: `https://appcms${env}.viewlift.com/${id}/main.json`,
  siteInfoCachedUrl: `https://${env}-api-internal.viewlift.com/content/sites?domainName=${domainName}`,
  siteConfigWebUrl: `https://appcmsstaging.viewlift.com/${id}/web.json?version=0.11140.0`,
  pageUrl: `https://${env}-api-${
    env === 'staging' ? 'internal' : 'cached-2'
  }.viewlift.com/content/pages`,
  moduleList: (pageId) =>
    `https://appcms${env}.viewlift.com/${id}/web/${pageId}.json`,
  favicon: `https://appcms${env}.viewlift.com/${id}/images/generated/favicon-16x16.png`,
  faviconTwo: `https://appcmsprod.viewlift.com/${id}/images/generated/favicon-32x32.png`,
  faviconThree: `https://appcmsprod.viewlift.com/${id}/images/generated/favicon.ico`,
  domainName,
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  firebaseApiKey: process.env.FIREBASE_API_KEY
}
