import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Loader from './components/Loader'
import config from '../../config'
import withRouter from './utils/withRouter'
import loadable from '@loadable/component'
import mapModule from './map'
import { getVerticalScrollPercentage, removeDuplicate } from './utils'
import useWindowScroll from './hooks/useWindowScroll'
import Skeleton from './components/Skeleton'

const Module = loadable(
  (props) =>
    import(/* webpackPreload: true */ `./components/${props.moduleName}`)
)

function LazyLoadModules (props) {
  const { moduleList, components, pathname, mweb, totalModuleCount } = props

  const [newModules, setNewModules] = useState(components)
  const location = useLocation()
  const navSettings = useSelector((state) => state?.pageSettings?.navigation)
  const isTablet = useSelector((state) => state.page?.isTablet)

  const [nextPageData, setNextPageData] = useState({
    isFetchingNextPage: false,
    hasNextPage: totalModuleCount > components.length,
    nextPageOffset: components.length
  })
  const [scrollPercent, setScrollPercentage] = useWindowScroll()

  const fetchModulesData = async (offset) => {
    const { pageUrl, apiKey, internalName } = config
    const lang = Cookies.get('userLanguage') || 'en'
    const cc = Cookies.get('hc-cc')

    const options = {
      method: 'GET',
      url: pageUrl,
      headers: {
        'x-api-key': apiKey
      },
      params: {
        path: pathname,
        site: internalName,
        includeContent: true,
        moduleOffset: offset,
        moduleLimit: 4,
        languageCode: lang,
        countryCode: cc
      }
    }

    return axios(options)
  }

  const fetchNextPage = async (offset) => {
    if (!nextPageData.hasNextPage || nextPageData.isFetchingNextPage) return
    setNextPageData((prev) => ({ ...prev, isFetchingNextPage: true }))
    try {
      const { data } = await fetchModulesData(offset)
      if (newModules.length + data.modules.length >= moduleList.length) {
        setNextPageData((prev) => ({ ...prev, hasNextPage: false }))
      }
      setNextPageData((prev) => ({
        ...prev,
        nextPageOffset: prev.nextPageOffset + 4
      }))
      setNewModules((prev) => {
        const result = prev.concat(data.modules)
        const filtered = removeDuplicate(result)
        return filtered
      })
    } catch (error) {
      //
    } finally {
      setNextPageData((prev) => ({ ...prev, isFetchingNextPage: false }))
    }
  }

  useEffect(() => {
    if (nextPageData.isFetchingNextPage) return

    if (scrollPercent > 90 && nextPageData.hasNextPage) {
      fetchNextPage(nextPageData.nextPageOffset).then(() => {
        setScrollPercentage(getVerticalScrollPercentage())
      })
    }
  }, [scrollPercent, nextPageData])

  return (
    <div style={props.topBanner ? { marginTop: '3rem' } : null}>
      {newModules.map((moduleData) => {
        if (!moduleData) return null
        const { id } = moduleData
        const { blockName = 'NA', settings = 'NA' } =
          moduleList.find(({ id: moduleID }) => moduleID === id) || {}
        const moduleName = mapModule(mweb, isTablet, location, navSettings)[
          blockName
        ]

        if (!moduleName) return null

        return (
          <div key={id}>
            <Module
              moduleData={moduleData}
              moduleName={moduleName}
              s={settings}
              mweb={mweb}
              {...props}
              fallback={
                !nextPageData.isFetchingNextPage && (
                  <div className='module-fallback-loader-wrapper'>
                    <Loader center />
                  </div>
                )
              }
            />
          </div>
        )
      })}
      {nextPageData.hasNextPage && (
        <Skeleton gap={isTablet || mweb ? '5px' : '8px'} />
      )}
    </div>
  )
}

export default withRouter(LazyLoadModules)
