import axios from 'axios'
import {
  GET_PAGE_DATA,
  GET_POPUP_DATA,
  SET_MWEB,
  REMOVE_PAGE_DATA
} from '../types'

export const getCachePageData = (path, type) => (dispatch) => {
  axios({
    method: 'GET',
    url: `/cache${path}`
  }).then((res) => {
    dispatch({
      type: GET_PAGE_DATA,
      payload: res.data
    })
  })
}
export const getPopupData = (path) => (dispatch) => {
  axios({
    method: 'GET',
    url: `/cache${path}`
  }).then((res) => {
    dispatch({
      type: GET_POPUP_DATA,
      payload: res.data
    })
  })
}

export const setMWeb = (mweb) => (dispatch) => {
  dispatch({
    type: SET_MWEB,
    payload: mweb
  })
}

export const removeCachePageData = () => (dispatch) => {
  dispatch({
    type: REMOVE_PAGE_DATA
  })
}
