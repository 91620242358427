import { useEffect, useState } from 'react'
import { debounce } from '../tools/globalFunc'
import { getVerticalScrollPercentage } from '../utils'

const useWindowScroll = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0)
  useEffect(() => {
    const scrollListener = debounce(() => {
      const value = getVerticalScrollPercentage()

      setScrollPercentage(value)
    }, 300)

    window.addEventListener('scroll', scrollListener)

    setScrollPercentage(getVerticalScrollPercentage())
    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return [scrollPercentage, setScrollPercentage]
}

export default useWindowScroll
