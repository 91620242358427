import { findParentCategory } from './utils/findParentCategory'

export default (isMWeb, isTablet, location, navSettings) => {
  const userModule =
    !isMWeb && !isTablet ? 'UserAccountManagement' : 'UserAccount'
  const isPathOnCategory = ['movies', 'shows'].includes(
    findParentCategory(
      location.pathname,
      navSettings?.primary
    )?.title?.toLowerCase()
  )

  return {
    masthead01: 'Navbar',
    // carousel04: 'CarouselV2',
    // carousel04: isPathOnCategory ? 'CategoryCarousel' : 'CarouselV2',
    carousel04: isPathOnCategory ? 'CategoryCarousel' : 'CarouselSlick',
    // carousel04: 'CarouselSlick',
    // carousel04: 'CarouselSlick',
    // carousel04: 'MoboCarousel',
    continueWatching01: 'ContinueWatching',
    // continueWatching03: 'VideoTiles',
    contentBlock01: 'VideoTiles',
    tray02: 'VideoTiles',
    // tray02: 'VideoTilesSlick',
    tray01: 'VideoTiles',
    // tray04: 'BigPosters',
    tray04: 'VideoTiles',
    banner01: 'Banner',
    // "10": "VideoBanner",
    carousel03: 'OriginalSeries',
    // footer02: 'BottomMenuBar',
    footer04: 'Footer',
    tray09: 'CategoryCarousel',
    videoPlayerInfo01: 'VideoDetailsPage',
    showDetail01: 'VideoDetailsPage',
    // selectPlan03: 'ViewPlan',
    selectPlan03: 'SubscriptionFlow',
    subscriptionFlow01: 'SubscriptionFlow',
    userManagement01: userModule,
    customNavigation: 'Contact',
    richText01: 'RawText',
    articleGrid01: 'Download',
    watchlist01: 'Watchlist',
    activateDevice01: 'ActivateDevice',
    rawHtml01: 'RawHtml',
    authentication01_activate_device: 'Login',
    search01: 'Search',
    redeemOffer01: 'Redeem',
    banner02: 'Banner02',
    ReferAndEarn01: 'Refer',
    history01: userModule,
    selectPlan05: 'MidWeekOffer'
  }
}
