import React from 'react'
// import { TrackJS } from 'trackjs';
import './styles.css'
import hoichoilogo from '../../assets/images/hoichoi-logo_140x40.png'

// TrackJS.install({
//   token: 'aa9ef52d3e5743f3b870a7c7e695928c',
// });

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    if (errorInfo?.componentStack) {
      // The component stack is sometimes useful in development mode
      // In production it can be somewhat obfuscated, so feel free to omit this line.
      console.log(errorInfo.componentStack)
    }

    // TrackJS.track(error);
    this.setState({ error })
  }

  componentDidMount () {
    document.body.style.width = '100vw'
  }

  render () {
    if (this.state.hasError) {
      return (
        <div className='error-boundaries-container'>
          <div className='error-boundaries-wrapper'>
            <div className='error-boundaries-parent'>
              <div className='error-boundaries-site-logo'>
                <img src={hoichoilogo} alt='hoichoi' width={100} height={30} />
              </div>
              <div className='error-boundaries-error-message-container'>
                <div className='error-boundaries-heading'>Error</div>
                <div className='error-boundaries-description'>
                  Something went wrong!
                </div>
                <div
                  className='error-boundaries-cta'
                  onClick={() => window.location.reload()}
                  role='button'
                >
                  Refresh
                </div>
                <div
                  role='button'
                  onClick={() => window.location.replace('/')}
                  className='error-boundaries-cta-secondary'
                >
                  Go to Home
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
