import React from 'react';
import './styles.css';

export default function Loader({ size, center = false, inline }) {
  return (
    <div
      className={`loader-container ${inline && 'inline'} ${size && size} ${
        center && 'center'
      }`}
    >
      <div className="loader-spinner" />
    </div>
  );
}
