import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// import pageReducer from './reducers/pageReducer';
import rootReducer from './reducers'

const middlewares = [thunk]
const composeEnhancers =
  (typeof window !== 'undefined' &&
    process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

// const composeEnhancers =
//   typeof window === 'object' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//     }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)
  // { trace: true }
  // other store enhancers if any
)
export default (initialState) =>
  createStore(rootReducer, initialState, enhancer)
