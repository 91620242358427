import Cookies from 'js-cookie';
import config from '../../../../config';

export const storeUser = (user) => {
  Cookies.set('vl-user', user && user.userId);
  Cookies.set('vl-redirect-user', user && user.userId, {
    domain: config.internalName,
  });
  window.localStorage.setItem('user', JSON.stringify(user));
};
