import { getToken } from '../tools/tokens';
import axios from 'axios';
import config from '../.././../config';

export const checkToken = async () => {
  const { internalName, apiKey, apiProxy } = config;

  try {
    const token = await getToken();
    const res = await axios({
      method: 'GET',
      url: `${apiProxy}/identity/user`,
      params: {
        site: internalName,
      },

      headers: {
        'x-api-key': apiKey,
        Authorization: token,
      },
    });
    
      return res.data
    
  } catch (err) {
    throw err
  }
};
