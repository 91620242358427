import axios from 'axios'
// customs
import { getToken } from '../tools/tokens'
import config from '../../../config'

export const getSubscriptionPlans = async (user) => {
  const { internalName, apiKey, apiProxy } = config

  try {
    const token = await getToken()
    const options = {
      method: 'GET',
      url: `${apiProxy}/subscription/plans`,
      params: {
        site: internalName,
        userId: user && user.userId,
        device: 'web_browser',
        monetizationModel: 'SVOD'
      },
      headers: {
        Authorization: token,
        'x-api-key': apiKey
      }
    }

    const response = await axios(options)
    return response.data
  } catch (err) {
    throw err
  }
}

export const getMidWeekSubscriptionPlans = async (id) => {
  const { apiKey, apiProxy } = config

  try {
    const token = await getToken()
    const options = {
      method: 'GET',
      url: `${apiProxy}/subscription/plans`,
      params: {
        ids: id,
        device: 'web_browser',
        allPlans: true
      },
      headers: {
        Authorization: token,
        'x-api-key': apiKey
      }
    }

    const response = await axios(options)
    return response.data
  } catch (err) {
    throw err
  }
}
