import { SET_API_REQUEST, RESET_API_REQUEST, REMOVE_ERROR } from '../types';
const initialState = { status: 'PRECALL' };

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_ERROR:
      return { ...state, ...action.payload };
    case RESET_API_REQUEST:
      return action.payload;
    case SET_API_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
