import React, { useEffect, useRef } from 'react';
import CrossIcon from '~/client/components/Icons/CrossIcon';
import './styles.css';

export default function Notifier({ message, onDismissed, notifier }) {
  const notifierContainerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (notifierContainerRef.current) {
        notifierContainerRef.current.classList.add('show');
      }
    }, 10);

    setTimeout(() => {
      if (notifierContainerRef.current) {
        notifierContainerRef.current.classList.remove('show');
        setTimeout(handleDismiss, 500);
      }
    }, 2500);

    return () => {};
  }, [notifier]);

  const handleDismiss = () => {
    if (typeof onDismissed === 'function') onDismissed();
  };

  function createMarkup() {
    return {
      __html: message,
    };
  }

  return (
    <div className="notifier-container" ref={notifierContainerRef}>
      <div className="notifier-wrapper">
        <div className="notifier-parent">
          <div
            className="notifier-message"
            dangerouslySetInnerHTML={createMarkup()}
          />
          <div
            className="notifier-close-icon"
            role="button"
            onClick={handleDismiss}
          >
            <CrossIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
