import React from 'react'
import './styles.css'
import mm_499_bw from './assets/mm_499_bw.svg'
import mm_499_color from './assets/mm_499_color.svg'
import mm_999_bw from './assets/mm_999_bw.svg'
import mm_999_color from './assets/mm_999_color.svg'
import offer_999 from './assets/12_3_999.svg'
import offer_999_color from './assets/12_3_999_color.svg'
import { useState } from 'react'
import dayjs from 'dayjs'

const offer_start = dayjs('2024-10-02 03:59')
const offer_end = dayjs('2024-10-02 08:00')

const MohishashurMordiniButton = () => {
  const [image999, setImage999] = useState(
    dayjs().isAfter(offer_start) && dayjs().isBefore(offer_end)
      ? offer_999
      : mm_999_bw
  )
  const [image499, setImage499] = useState(mm_499_bw)

  return (
    <div className="section--def">
      <div className="btn-container">
        <button
          className="_999"
          onMouseOver={() => {
            const now = dayjs()
            if (now.isAfter(offer_start) && now.isBefore(offer_end)) {
              setImage999(offer_999_color)
            } else {
              setImage999(mm_999_color)
            }
          }}
          onMouseOut={() => {
            const now = dayjs()
            if (now.isAfter(offer_start) && now.isBefore(offer_end)) {
              setImage999(offer_999)
            } else {
              setImage999(mm_999_bw)
            }
          }}
          onClick={() =>
            (window.location.href = 'https://www.hoichoi.tv/subscribe')
          }
        >
          <img src={image999} alt="" />
        </button>
        <button
          className="_499"
          onMouseOver={() => setImage499(mm_499_color)}
          onMouseOut={() => setImage499(mm_499_bw)}
          onClick={() =>
            (window.location.href = 'https://www.hoichoi.tv/subscribe')
          }
        >
          <img src={image499} alt="" />
        </button>
      </div>
    </div>
  )
}

export default MohishashurMordiniButton
