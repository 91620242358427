import axios from 'axios'
// customs
import config from '../../../config'
import { getToken } from '../tools/tokens'

export const forgotPassword = async (data) => {
  const { internalName, apiKey, apiProxy } = config

  try {
    const res = await axios({
      method: 'POST',
      url: `${apiProxy}/identity/password/forgot`,
      params: {
        site: internalName
      },
      data,
      headers: {
        'x-api-key': apiKey
      }
    })
    return res
  } catch (err) {
    throw err
  }
}

export const resetPassword = async (resetToken, password) => {
  const { internalName, apiKey, apiProxy } = config

  const res = await axios({
    method: 'POST',
    url: `${apiProxy}/identity/password`,
    params: {
      site: internalName
    },
    data: resetToken,
    password,
    headers: {
      'x-api-key': apiKey
    }
  })
  return res.response
}

export const resendOtp = async (phoneNumber) => {
  const { internalName, apiKey, apiProxy } = config

  try {
    const token = await getToken()
    const res = await axios({
      method: 'POST',
      url: `${apiProxy}/identity/otp/resend`,
      params: {
        site: internalName
      },
      data: phoneNumber,
      headers: {
        'x-api-key': apiKey
      }
    })

    return res.data
  } catch (err) {
    throw err
  }
}
export const updatePhoneNumber = async (data, userId) => {
  const { internalName, apiKey, apiProxy } = config

  try {
    const token = await getToken()
    const res = await axios({
      method: 'POST',
      url: `${apiProxy}/identity/user/updatePhone`,
      params: {
        site: internalName,
        userId
      },
      data,
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    })

    return res.data
  } catch (err) {
    throw err
  }
}
export const userItemInfo = async (videoId) => {
  const { internalName, apiKey, apiProxy } = config

  try {
    const token = await getToken()
    const res = await axios({
      method: 'GET',
      url: `${apiProxy}/user/info/video/${videoId}`,
      params: {
        site: internalName
      },

      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    })

    return res.data
  } catch (err) {
    throw err
  }
}
