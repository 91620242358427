export const getCurrentSeasonAndEpisodeNumber = (
  seasonsData,
  currentVideoId
) => {
  try {
    let currentEpisodeNumber, currentSeasonNumber
    seasonsData.some((season, index) => {
      currentSeasonNumber = index + 1
      currentEpisodeNumber = season.episodes.findIndex(
        (episode) => episode.id === currentVideoId
      )
      if (currentEpisodeNumber !== -1) return true
    })
    return {
      currentSeasonNumber,
      currentEpisodeNumber: currentEpisodeNumber + 1
    }
  } catch (error) {}
}

export const getNextEpisodeId = (seasonsData, currentVideoId) => {
  let nextEpisodeId = ''
  seasonsData.map((season, seasonNumber) => {
    season.episodes.filter((episode, episodeNumber) => {
      if (
        episode.id === currentVideoId
        // !(season.episodes.length - 1 === episodeNumber)
      ) {
        nextEpisodeId =
          seasonsData[seasonNumber].episodes[episodeNumber + 1]?.id
      }
    })
  })
  return nextEpisodeId
}

export const isFreeContent = (videoId) => {
  const data = contentData[0]
  const { seasons = false } = data
  let isFree = false
  if (seasons) {
    seasons.map((season) => {
      season.episodes.filter((episode) => {
        if (episode.id === videoId) {
          isFree = episode.gist.free
        }
      })
    })
  } else isFree = data.gist.free

  return isFree
}
