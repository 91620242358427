import { useEffect } from 'react'

function useDisableDevTools() {
  useEffect(() => {
    // const __prod__ = window.location.hostname.endsWith('tv')
    // if (!__prod__) {
    //   return
    // }
    // Disable right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault()
    }

    // Disable common keyboard shortcuts, including MacOS Option+Command+I
    const handleKeyDown = (e) => {
      if (
        e.keyCode === 123 || // F12
        (e.ctrlKey && e.shiftKey && e.keyCode === 73) || // Ctrl+Shift+I (Windows/Linux)
        (e.ctrlKey && e.shiftKey && e.keyCode === 74) || // Ctrl+Shift+J (Windows/Linux)
        (e.ctrlKey && e.keyCode === 85) || // Ctrl+U (Windows/Linux)
        (e.metaKey && e.altKey && e.keyCode === 73) // Option+Command+I (MacOS)
      ) {
        e.preventDefault()
      }
    }

    // Detect DevTools open
    const detectDevTools = () => {
      const threshold = 160
      const isDevToolsOpen =
        window.outerWidth - window.innerWidth > threshold ||
        window.outerHeight - window.innerHeight > threshold

      if (isDevToolsOpen && window.location.hostname.endsWith('tv')) {
        // Trigger a debugger when DevTools is opened
        debugger
      }
    }

    // Interval to repeatedly check for DevTools
    const devToolsInterval = setInterval(detectDevTools, 1000)

    // Handle orientation change on iOS (which can indicate DevTools opening)
    const handleOrientationChange = () => {
      detectDevTools()
    }

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu)
    document.addEventListener('keydown', handleKeyDown)
    window.addEventListener('resize', detectDevTools)
    window.addEventListener('orientationchange', handleOrientationChange)

    // Run the detection immediately in case DevTools are already open
    detectDevTools()

    // Cleanup
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu)
      document.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('resize', detectDevTools)
      window.removeEventListener('orientationchange', handleOrientationChange)
      clearInterval(devToolsInterval)
    }
  }, [])
}

export default useDisableDevTools
