import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import config from '../../../config'

let tokenData
if (typeof window === 'undefined') {
  tokenData = {}
} else {
  const savedToken = window.localStorage.getItem('token')
  tokenData = (savedToken && JSON.parse(savedToken)) || {}
}

if (tokenData && tokenData.expiration) {
  tokenData.expiration = new Date(tokenData.expiration)
}

export const decodeToken = (token) => {
  const authToken = jwtDecode(token)
  const tokenExp = authToken.exp
  const date = new Date(tokenExp * 1000)
  return date
}

export const setToken = (token) => {
  if (!token || !token.authorizationToken) {
    throw new Error('Invalid request to update token')
  }

  const tokenExpiryTime = decodeToken(token.authorizationToken)
  tokenData = {
    expiration: tokenExpiryTime.getTime(),
    authorizationToken: token.authorizationToken,
    refreshToken: token.refreshToken,
    duration: tokenExpiryTime.getTime() - new Date().getTime()
  }
  window.localStorage.setItem('token', JSON.stringify(tokenData))
}

export const clearToken = () => {
  window.localStorage.removeItem('token')
  Cookies.remove('vl-user')
  Cookies.remove('vl-redirect-user')
  window.localStorage.removeItem('user')
  tokenData = {}
}

// if (tokenData.authorizationToken) {
//   var now = new Date().getTime();
//   if (now < tokenData.expiration) {
//     if (cb) cb(tokenData.authorizationToken, tokenData.refreshToken)
//     resolve(tokenData.authorizationToken, tokenData.refreshToken)
//     return
//   }
// }

export const getToken = async () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const { apiProxy, apiKey, internalName } = config
  const { authorizationToken, refreshToken, expiration } = tokenData
  const {
    authorizationToken: userAuthorizationToken,
    refreshToken: userRefreshToken
  } = user

  if (authorizationToken) {
    const now = new Date().getTime()
    if (now < expiration) {
      return authorizationToken
    }
  }

  if (refreshToken || userAuthorizationToken) {
    try {
      const res = await axios({
        method: 'GET',
        url: `${apiProxy}/identity/refresh/${refreshToken || userRefreshToken}`
      })

      const { authorization_token, refresh_token } = res.data || {}
      const _token = {
        authorizationToken: authorization_token,
        refreshToken: refresh_token
      }
      setToken(_token)
      return authorization_token
    } catch (err) {
      clearToken()
    }

    // if user , remove user
  }

  try {
    const res = await axios({
      method: 'GET',
      url: `${apiProxy}/identity/anonymous-token`,
      params: {
        site: internalName
      },
      headers: {
        'x-api-key': apiKey
      }
    })

    const { authorizationToken } = res.data || {}
    setToken({ authorizationToken })
    return authorizationToken
  } catch (err) {
    console.error('Error fetching anonymous token', err)
  }
}
