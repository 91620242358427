import React from 'react'
import '~/client/components/Icons/styles.css'
import Loader from '../../Loader'

export default function PlusIcon ({
  isAddedToWatchlist,
  watchListUpdate,
  ...restProps
}) {
  return watchListUpdate
    ? (
      <Loader size='sm' />
      )
    : isAddedToWatchlist
      ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={13}
          height={10}
          viewBox='0 0 13 10'
          {...restProps}
        >
          <g
            fill='none'
            fillRule='evenodd'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <g stroke='#FFF' strokeWidth={2}>
              <path
                d='M10.476 0L3.274 7.857 0 4.286'
                transform='translate(-884 -428) translate(820 288) translate(30 85) translate(35 51) translate(0 5)'
              />
            </g>
          </g>
        </svg>
        )
      : (
        <svg viewBox='0 0 426.66667 426.66667' className='icons' {...restProps}>
          <path
            d='m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0'
            {...restProps}
          />
        </svg>
        )
}
