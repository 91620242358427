import axios from 'axios'

// customs
import { WATCHLIST_UPDATE_REQUEST, WATCHLIST_UPDATE_SUCCESS } from '../types'
import config from '../../../../config'
import { getToken } from '../../tools/tokens'
import {
  setUserDetails,
  setRequest,
  resetRequest,
  updateUserDetails,
  showNotifier
} from './helpers'
import Cookies from 'js-cookie'
import { trackAdjustEvent, ADJUST_EVENTS } from '~/client/tools/adjustInIt'
import { trackCleverTapEvent, CLEVERTAP_EVENTS } from '~/client/tools/clevertap'
import { getCurrentSeasonAndEpisodeNumber } from '~/client/components/VideoPlayer/helper'
import { trackGAEvent } from '~/client/tools/googleAnalytics'

const _axios = async (dispatch, { method, url, addParams, data = {} }) => {
  const { apiKey, apiProxy, internalName } = config
  resetRequest(dispatch)
  try {
    const token = await getToken()
    const res = await axios({
      method,
      url: `${apiProxy}${url}`,
      params: {
        site: internalName,
        ...addParams
      },
      headers: {
        Authorization: token,
        'x-api-key': apiKey
      },
      data
    })
    setRequest(dispatch, { status: 'SUCCESS' })
    return res.data
  } catch (err) {
    setRequest(dispatch, { err: err.message })
    throw err
  } finally {
    setRequest(dispatch, { status: 'PRECALL' })
  }
}

// watchlist - add, remove, fetch
export const getWatchlist = (user) => async (dispatch) => {
  const { id: userId } = user

  try {
    const watchlist = await _axios(dispatch, {
      method: 'GET',
      url: '/user/queues',
      addParams: { userId }
    })

    const { records = [] } = watchlist
    const watchVideos = records.map(({ contentResponse }) => {
      const id = contentResponse?.gist?.id
      return id
    })
    setUserDetails(dispatch, { watchlist: watchVideos })
  } catch (err) {}
}

// contentId: "0000015e-9bb7-dfab-addf-fbbf27aa0000"
// contentType: "VIDEO"
// position: 1
// userId: "56fe04a8-0dc2-454d-b806-e5fdd070a3e2"

export const addToWatchlist = (videoDetails, user) => async (dispatch) => {
  const selectedLanguage = Cookies.get('userLanguage') || 'en'
  const { id: userId } = user
  const { contentId } = videoDetails
  const lang = Cookies.get('userLanguage') || 'en'

  const contentDuration = videoDetails?.videoData?.gist?.runtime || ' runtime' // chaiye

  const contentMediaType = videoDetails?.videoData.gist.mediaType // noai chaiye

  const contentType = videoDetails?.videoData.gist.isTrailer
    ? 'Trailer'
    : contentMediaType === 'SERIES'
      ? 'Episode'
      : 'Video'

  const platform = 'web'

  const playbackType = 'Streamed'

  const contentTitle = videoDetails?.videoData.gist.title || ''

  const networkType = window.navigator.connection
    ? window.navigator.connection.type
      ? window.navigator.connection.type
      : window.navigator.connection.effectiveType
    : 'wifi'

  const contentGenre =
    videoDetails?.videoData?.gist?.primaryCategory?.title || 'contentGen'

  const directorsName = {}
  const starringName = {}

  videoDetails?.videoData?.creditBlocks?.forEach((creditItem) => {
    if (creditItem?.title === 'Director') {
      creditItem.credits.forEach((dirEle, index) => {
        directorsName[`director ${index + 1}`] = dirEle.title
      })
    }
    if (creditItem?.title === 'Starring') {
      creditItem.credits.forEach((starEle, index) => {
        starringName[`Starring ${index + 1}`] = starEle.title
      })
    }
  })
  const seasonDetails = getCurrentSeasonAndEpisodeNumber(
    videoDetails?.videoData?.seasons,
    videoDetails?.videoData?.gist?.id
  )

  const email = user?.email || 'email'

  const pageId = localStorage.getItem('pageId')
  const deviceId = localStorage.getItem('deviceId')

  const userID = user?.id || user?.userId || 'userId'

  const phoneNumber = user?.phoneNumber || 'phoneNumber'

  const payloadForAddToWatchListEvent = {
    contentDuration,
    contentType,
    platform,
    playbackType,
    contentTitle,
    networkType,
    contentGenre,
    contentId,
    ...directorsName,
    ...starringName,
    ...seasonDetails,
    email,
    pageId,
    deviceId,
    userID,
    phoneNumber
  }

  trackCleverTapEvent(
    CLEVERTAP_EVENTS.ADDED_TO_WATCHLIST,
    payloadForAddToWatchListEvent
  )

  trackGAEvent('Added_to_Watchlist', payloadForAddToWatchListEvent)

  // trackCTEvent({
  //   event: 'Added_to_Watchlist',
  //   track: true,
  //   details: payloadForAddToWatchListEvent,
  // });
  trackAdjustEvent(
    ADJUST_EVENTS.ADDED_TO_WATCHLIST,
    payloadForAddToWatchListEvent
  )

  // category, action, label

  try {
    const token = await getToken()
    dispatch({ type: WATCHLIST_UPDATE_REQUEST })
    await _axios(dispatch, {
      method: 'POST',
      url: '/user/queues',
      headers: {
        Authorization: token,
        'x-api-key': config.apiKey
      },
      data: { userId, ...videoDetails }
    })

    updateUserDetails(dispatch, {
      to: 'ADD',
      key: 'watchlist',
      value: contentId
    })

    dispatch({ type: WATCHLIST_UPDATE_SUCCESS })

    showNotifier(dispatch, {
      message:
        lang === 'bn' ? 'ওয়াচলিস্টে যোগ করা হয়েছে' : 'Added to watchlist'
    })
  } catch (err) {
    console.log(err, 'err')
    const errAddToWatchlist =
      selectedLanguage === 'bn'
        ? 'ওয়াচলিস্টে যোগ করার সময় ত্রুটি। পরে আবার চেষ্টা করুন!'
        : 'Error while adding to watchlist. Please try again later!'

    dispatch({ type: WATCHLIST_UPDATE_SUCCESS })
    showNotifier(dispatch, {
      message: errAddToWatchlist
    })
  }
}

export const removeFromWatchlist = (videoDetails, user) => async (dispatch) => {
  const selectedLanguage = Cookies.get('userLanguage') || 'en'
  const { userId } = user
  const { contentId } = videoDetails
  const { internalName } = config
  const lang = Cookies.get('userLanguage') || 'en'

  const contentDuration = videoDetails?.videoData?.gist?.runtime || ' runtime' // chaiye

  const contentMediaType = videoDetails?.videoData.gist.mediaType // noai chaiye

  const contentType = videoDetails?.videoData.gist.isTrailer
    ? 'Trailer'
    : contentMediaType === 'SERIES'
      ? 'Episode'
      : 'Video'

  const platform = 'web'

  const playbackType = 'Streamed'

  const contentTitle = videoDetails?.videoData.gist.title || ''

  const networkType = window.navigator.connection
    ? window.navigator.connection.type
      ? window.navigator.connection.type
      : window.navigator.connection.effectiveType
    : 'wifi'

  const contentGenre =
    videoDetails?.videoData?.gist?.primaryCategory?.title || 'contentGen'

  const directorsName = {}
  const starringName = {}

  videoDetails?.videoData?.creditBlocks?.forEach((creditItem) => {
    if (creditItem?.title === 'Director') {
      creditItem.credits.forEach((dirEle, index) => {
        directorsName[`director ${index + 1}`] = dirEle.title
      })
    }
    if (creditItem?.title === 'Starring') {
      creditItem.credits.forEach((starEle, index) => {
        starringName[`Starring ${index + 1}`] = starEle.title
      })
    }
  })
  const seasonDetails = getCurrentSeasonAndEpisodeNumber(
    videoDetails?.videoData?.seasons,
    videoDetails?.videoData?.gist?.id
  )

  const email = user?.email || 'email'

  const pageId = localStorage.getItem('pageId')
  const deviceId = localStorage.getItem('deviceId')

  const userID = user?.id || user?.userId || 'userId'

  const phoneNumber = user?.phoneNumber || 'phoneNumber'

  //
  // const payloadForRemoveFromWatchListEvent = {
  //   userId: userId,
  //   ...(videoDetails && { ...videoDetails }),
  // };

  const payloadForRemoveFromWatchListEvent = {
    contentDuration,
    contentType,
    platform,
    playbackType,
    contentTitle,
    networkType,
    contentGenre,
    contentId,
    ...directorsName,
    ...starringName,
    ...seasonDetails,
    email,
    pageId,
    deviceId,
    userID,
    phoneNumber
  }

  trackCleverTapEvent(
    CLEVERTAP_EVENTS.REMOVED_FROM_WATCHLIST,
    payloadForRemoveFromWatchListEvent
  )

  // trackCTEvent({
  //   event: 'Removed_From_Watchlist',
  //   track: true,
  //   details: payloadForRemoveFromWatchListEvent,
  // });
  trackAdjustEvent(
    ADJUST_EVENTS.REMOVED_FROM_WATCHLIST,
    payloadForRemoveFromWatchListEvent
  )

  trackGAEvent('Removed_From_Watchlist', payloadForRemoveFromWatchListEvent)

  // category, action, label

  try {
    dispatch({ type: WATCHLIST_UPDATE_REQUEST })
    await _axios(dispatch, {
      method: 'DELETE',
      url: '/user/queues',
      addParams: { userId, contentIds: contentId },
      data: { userId, contentId, site: internalName }
    })

    updateUserDetails(dispatch, {
      to: 'REMOVE',
      key: 'watchlist',
      value: contentId
    })
    dispatch({ type: WATCHLIST_UPDATE_SUCCESS })
    showNotifier(dispatch, {
      message:
        lang === 'bn'
          ? 'ওয়াচলিস্ট থেকে সরানো হয়েছে'
          : 'Removed from watchlist '
    })
  } catch (err) {
    const errRemoveFromWatchlist =
      selectedLanguage === 'bn'
        ? 'ওয়াচলিস্ট থেকে সরানোর সময় ত্রুটি৷'
        : 'Error while removing from watchlist'

    dispatch({ type: WATCHLIST_UPDATE_SUCCESS })

    showNotifier(dispatch, { message: errRemoveFromWatchlist })
  }
}

export function removeFromQueue (videoId, videoInfo, cb, cbCatch) {
  const { apiKey, apiProxy, internalName } = config
  getUser((user) => {
    getToken((token) => {
      axios({
        method: 'DELETE',
        url: `${apiProxy}/user/queues`,
        params: {
          site: internalName,
          userId: user.userId,
          contentIds: videoId
        },
        headers: {
          Authorization: token,
          'x-api-key': apiKey
        }
      })
        .then((res) => {
          // cache[videoId] = null
          if (cache[videoId]) cache[videoId].isQueued = false
          const ga = window.ga
          if (ga) {
            ga('send', {
              hitType: 'event',
              eventCategory: 'Watchlist',
              eventAction: 'Removed from Watchlist',
              eventLabel: videoInfo
            })
          }
          getVideoInfo(videoId, cb)
        })
        .catch((err) => {
          cbCatch()
          console.error(err)
        })
    })
  })
}

// like,unlike, fetch liked videos

export const getLikedVideos = (videoDetails, user) => async (dispatch) => {
  const { contendId } = videoDetails
  const { id } = user
  try {
    const likedVideos = await _axios(dispatch, {
      method: 'GET',
      url: '/user/likes',
      addParams: { contendId, userId: id }
    })

    const { records = [] } = likedVideos
    const videos = records.map(({ contentId }) => {
      return contentId
    })
    setUserDetails(dispatch, { likes: videos })
  } catch (err) {
    console.log(err, 'err')
  }
}

export const likeAVideo = (videoDetails, user) => async (dispatch) => {
  const { userId } = user
  const { contentType, id: contentId, title } = videoDetails

  try {
    const liked = _axios(dispatch, {
      method: 'POST',
      url: '/user/likes',
      addParams: { userId },
      data: { contentId, contentType, title }
    })
    updateUserDetails(dispatch, { to: 'ADD', key: 'likes', value: contentId })
  } catch (err) {}
}
export const unlikeVideo = (videoDetails, user) => async (dispatch) => {
  const { id: userId } = user
  const { id: contentId, contentType, title } = videoDetails

  try {
    const liked = _axios(dispatch, {
      method: 'DELETE',
      url: `/user/likes/${contentId}`,
      addParams: { userId },
      data: { contentId, contentType, title }
    })

    updateUserDetails(dispatch, {
      to: 'REMOVE',
      key: 'likes',
      value: contentId
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}
