const ga4Id = 'G-6BN5EBJ87B'

function gtag () {
  if (window.dataLayer) window.dataLayer.push(arguments)
}

export const initGtmAdwords = () => {
  gtag('js', new Date())
  gtag('config', ga4Id)
}

export function trackPageViewGa4 (eventName, eventData) {
  ga4Id &&
    gtag('event', eventName, {
      ...eventData
    })
}
export function trackGAEvent (eventName, eventData) {
  ga4Id &&
    gtag('event', eventName, {
      ...eventData
    })
}

// To track the registration event

export function trackRegistrationEvent (url) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  gtag('event', 'conversion', {
    send_to: 'AW-853078675/3Rd5CI7Z_M8YEJPl45YD',
    event_callback: callback
  })
  return false
}

// To track the subscription Initiated event
export function trackSubscroptionInitiatedEvent (url) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  gtag('event', 'conversion', {
    send_to: 'AW-853078675/jEUFCIa__M8YEJPl45YD',
    event_callback: callback
  })
  return false
}

// To track subscription Completed event

export function trackSubscroptionCompletedEvent (eventName, data, url) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  const { transactionId, amount, currency } = data
  gtag('event', eventName, 'conversion', {
    send_to: 'AW-853078675/q_J0COaakncQk-XjlgM',
    value: amount,
    currency,
    transaction_id: transactionId,
    event_callback: callback
  })
  return false
}

// To track the page View  event
export function trackPageViewEvent () {
  gtag('event', 'conversion', { send_to: 'AW-853078675/D14NCIy__M8YEJPl45YD' })
}

// To track the subscription event
export function trackViewPlansEvent (url) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }
  gtag('event', 'conversion', {
    send_to: 'AW-853078675/JQg_CIm__M8YEJPl45YD',
    event_callback: callback
  })
  return false
}
