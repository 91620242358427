import Cookies from 'js-cookie';

const UTM_PARAMS = ['utm_campaign', 'utm_source', 'utm_medium'];

const setUTM = () => {
  if (typeof window === 'undefined') return;

  const queryString = document.location.search.slice(1);
  if (!queryString.includes('utm_')) return;

  const queries = queryString.split('&');
  const utmQueries = queries.reduce((utmValues, query) => {
    const [param, value] = query.split('=');
    if (UTM_PARAMS.includes(param)) {
      utmValues[param] = value;
    }
    return utmValues;
  }, {});

  if (Object.keys(utmQueries).length) {
    Cookies.set('utm', JSON.stringify(utmQueries), { expires: 60, path: '' });
  }
};

export default setUTM;
