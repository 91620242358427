export const GET_PAGE_DATA = 'GET_PAGE_DATA'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_USER_LOGIN = 'SET_USER_LOGIN'
export const SET_USER_SIGNUP = 'SET_USER_SIGNUP'
export const SET_USER_LOGOUT = 'SET_USER_LOGOUT'
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const GET_POPUP_DATA = 'GET_POPUP_DATA'
export const SET_API_REQUEST = 'SET_API_REQUEST'
export const RESET_API_REQUEST = 'RESET_API_REQUEST'
export const SET_POPUP = 'SET_POPUP'
export const SET_SIZE = 'SET_SIZE'
export const SET_MWEB = 'SET_MWEB'
export const REMOVE_ERROR = 'REMOVE_ERROR'
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const REMOVE_PAGE_DATA = 'REMOVE_PAGE_DATA'
export const NEW_PAGE_SETTINGS = 'NEW_PAGE_SETTINGS'
export const SET_PAGE_SETTINGS = 'SET_PAGE_SETTINGS'
export const SET_NEXT_EPISODE_ID = 'SET_NEXT_EPISODE_ID'
export const REMOVE_NEXT_EPISODE_ID = 'REMOVE_NEXT_EPISODE_ID'
export const WATCHLIST_UPDATE_REQUEST = 'WATCHLIST_UPDATE_REQUEST'
export const WATCHLIST_UPDATE_SUCCESS = 'WATCHLIST_UPDATE_SUCCESS'
export const PARENTAL_PIN_ACCESS = 'PARENTAL_PIN_ACCESS'
export const SET_GRAMIN_USER_DETAILS = 'SET_GRAMIN_USER_DETAILS'
