import { getToken } from '../tools/tokens';
import { apiKey, apiProxy, internalName } from '../../../config';
import Axios from 'axios';

export const saveWatchHistory = async (data) => {
  const authToken = await getToken();
  return Axios({
    url: '/user/userId/history/video',
    baseURL: apiProxy,
    method: 'POST',
    headers: {
      Authorization: authToken,
      'x-api-key': apiKey,
    },
    params: { version: 'v2' },
    data: JSON.stringify(data),
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      let error =
        err && err.response && err.response.data && err.response.data.errorCode;
      let errorMessage =
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errorMessage;
      let errorData = {
        error: error,
        errorMessage: errorMessage,
      };
      return errorData;
    });
};

export const fetchVideoDetails = async (videoId) => {
  const authToken = await getToken();
  const axiosOptions = {
    method: 'GET',
    headers: {
      Authorization: authToken,
    },
    url: `${apiProxy}/entitlement/video/status`,
    params: {
      id: videoId,
      deviceType: 'web_browser',
      contentConsumption: 'web',
    },
  };
  return Axios(axiosOptions)
    .then((res) => res)
    .catch((err) => err);
};

export const fetchSeriesWatchHistory = async (seriesId) => {
  const authToken = await getToken();
  return Axios({
    url: '/user/userId/history/video',
    baseURL: apiProxy,
    method: 'GET',
    headers: {
      Authorization: authToken,
      'x-api-key': apiKey,
    },
    params: { version: 'v2', site: internalName, seriesId: seriesId },
  })
    .then((res) => res)
    .catch((err) => err);
};

export const getVideoInfo = async (videoId) => {
  const authToken = await getToken();
  return Axios({
    method: 'GET',
    url: `${apiProxy}/user/info/video/${videoId}`,
    params: { site: internalName },
    headers: {
      Authorization: authToken,
      'x-api-key': apiKey,
    },
  })
    .then((res) => res)
    .catch((err) => err);
};
