import axios from 'axios'
import Cookies from 'js-cookie'

// customs
import config from '../../../../config'
import { getToken, setToken, clearToken } from '../../tools/tokens'
import {
  setUserDetails,
  generateId,
  setRequest,
  resetRequest,
  setLogout
} from './helpers'
import { getMySubscription } from './userEditActions'
import { segementTracker as trackCTEvent } from '~/client/tools/analytics'

import { ADJUST_EVENTS, trackAdjustEvent } from '../../tools/adjustInIt'
import { FB_EVENTS, trackFbEvent } from '~/client/tools/fbInit'
import {
  addUserProfile,
  trackCleverTapEvent,
  CLEVERTAP_EVENTS
} from '~/client/tools/clevertap'
import {
  trackGAEvent,
  trackRegistrationEvent
} from '~/client/tools/googleAnalytics'

// export const removeError = () => (dispatch) => {
//   setRemoveError(dispatch);
// };

const _axios = async (
  dispatch,
  data,
  method,
  url,
  genrateId,
  setToLocal,
  additionalParams
) => {
  // setRequest(dispatch, 'CALLING');
  resetRequest(dispatch)
  // clearError(dispatch);
  const { apiKey, apiProxy, internalName } = config
  let deviceId
  deviceId = genrateId
    ? 'browser-' + generateId()
    : localStorage.getItem('deviceId')
  try {
    const response = await axios({
      method,
      url: `${apiProxy}${url}`,
      params: {
        site: internalName,
        deviceId,
        ...additionalParams
      },
      data,
      headers: {
        'x-api-key': apiKey
      }
    })

    if (setToLocal) {
      localStorage.setItem('deviceId', deviceId)
    }

    setRequest(dispatch, { status: 'SUCCESS' })
    return response.data
  } catch (err) {
    //
    setRequest(dispatch, { err: err.response.data })
    throw err
  } finally {
    setTimeout(() => {
      setRequest(dispatch, { status: 'PRECALL' })
    }, 1500)
  }
}

const onLogin = async ({ dispatch, user, loginMethod, pageId }) => {
  setToken(user)
  setUserDetails(dispatch, { ...user, auth: true, loginMethod })
  Cookies.set('auth', true)
  Cookies.set('isSubscribed', user.isSubscribed)
  const data = await refreshUser()(dispatch)

  addUserProfile('Site', {
    Name: user?.name || '',
    Email: user?.email || '',
    Identity: user.id || user.userId || '',
    'User Status': user?.isSubscribed
      ? 'Subscribed'
      : 'Registered and Never Subscribed'
  })

  const payloadForLogin = {
    userId: data.id || '',
    platform: 'Web',
    paymentPlan: data?.monetizationPlan?.planDetails[0]?.description || '',
    country: data.countryName,
    city: data?.city || '',
    firstName: data?.name || '',
    email: data.email,
    phone: data.phoneNumber,
    registrationType: data?.registerdVia || '',
    numberofDevices: data?.devices?.count || '',
    pageId,
    screenId: pageId,
    deviceId: window.localStorage.getItem('deviceId'),
    paymentHandler: data?.subscription?.subscriptionInfo?.paymentHandler || '',
    registerdVia: data?.registerdVia || ''
  }

  setTimeout(() => {
    trackCleverTapEvent(CLEVERTAP_EVENTS.LOGIN, payloadForLogin)
    trackFbEvent(FB_EVENTS.LOGIN, payloadForLogin)

    // fbq('trackCustom', 'Log_In', payloadForLogin)

    // trackCTEvent({
    //   event: 'Log_In',
    //   track: true,
    //   details: payloadForLogin,
    // });
    trackAdjustEvent(ADJUST_EVENTS.LOGIN, payloadForLogin)

    trackGAEvent('Log_In', payloadForLogin)
  }, 3000)
  getMySubscription(user)(dispatch)
  trackCTEvent({
    event: 'Login',
    track: true,
    details: {
      userId: user.id,
      platform: 'Web',
      registrationType: loginMethod
    }
  })
}

export const login = (creds) => async (dispatch) => {
  const method = 'POST'
  const url = '/identity/signin'
  const isForOtp = creds.requestType
  const pageId = creds.pageId

  try {
    const user = await _axios(dispatch, creds, method, url, true, true, {})
    if (!isForOtp) {
      onLogin({ dispatch, user, loginMethod: 'email', pageId })
    }
    // if (res.data.code === "DEVICE_LIMIT_EXCEEDED") {
    //   window.location.href = '/user'
    // }
  } catch (err) {}
}
// otpValue: "1111"
// phoneNumber: "+918287789199"
// requestType: "verify"
// whatsappConsent: false
export const verifyOtp = (data) => async (dispatch) => {
  const { isSignUp, pageId } = data
  const method = 'POST'
  const url = `/identity/${isSignUp ? 'signup' : 'signin'}`
  const _data = { ...data, requestType: 'verify', whatsappConsent: false }

  try {
    const user = await _axios(dispatch, _data, method, url, false, false, {})
    if (isSignUp) {
      addUserProfile('Site', {
        Name: user?.name || '',
        Email: user?.email || '',
        Identity: user.id || user.userId || '',
        'User Status': user?.isSubscribed
          ? 'Subscribed'
          : 'Registered and Never Subscribed'
      })

      const payloadForSignUp = {
        userId: user.id || user.userId || '',
        platform: 'Web',
        paymentPlan: user?.monetizationPlan?.planDetails[0]?.description || '',
        country: user.countryName,
        city: user.city,
        firstName: user?.name || '',
        email: user.email,
        phone: user.phoneNumber,
        pageId: pageId || '',
        deviceId: window.localStorage.getItem('deviceId') || ''
      }

      trackCleverTapEvent(CLEVERTAP_EVENTS.SignedUp_, payloadForSignUp)

      trackGAEvent('Sign_Up', payloadForSignUp)

      trackRegistrationEvent()

      // trackCTEvent({
      //   event: 'SIGN_UP',
      //   track: true,
      //   details: payloadForSignUp,
      // });
      trackAdjustEvent(ADJUST_EVENTS.SignedUp_, payloadForSignUp)
      // fbq('trackCustom', 'SignedUp_', payloadForSignUp)
      trackFbEvent(FB_EVENTS.SignedUp_, payloadForSignUp)
    }

    onLogin({ dispatch, user, loginMethod: 'phone', pageId })
  } catch (err) {}
}

export const signup = (creds) => async (dispatch) => {
  const method = 'POST'
  const url = '/identity/signup'
  const utmCookie = Cookies.get('utm')
  const utmData = utmCookie && JSON.parse(utmCookie)
  const signupParams = {
    campaign: utmData && utmData.utm_campaign,
    campaign_source: utmData && utmData.utm_source,
    campaign_medium: utmData && utmData.utm_medium,
    utm_params: utmData && utmData.utm_params
  }
  const isForOtp = creds.requestType == 'send'

  try {
    const user = await _axios(
      dispatch,
      creds,
      method,
      url,
      true,
      true,
      signupParams
    )
    if (!isForOtp) {
      onLogin({ dispatch, user, loginMethod: 'email' })
    }
  } catch (err) {}
}

export const logout = (user) => async (dispatch) => {
  const { apiKey, apiProxy, internalName } = config
  // const { istveLoggedIn } = user;

  try {
    const token = await getToken()
    ;['deviceId', 'payment', 'order', 'user', 'plan'].forEach((item) => {
      window.localStorage.removeItem(item)
    })
    ;['vl-user', 'auth', 'isSubscribed'].forEach((item) => {
      Cookies.remove(item)
    })

    await axios({
      method: 'POST',
      url: `${apiProxy}/identity/signout`,
      params: { site: internalName },
      headers: {
        Authorization: token,
        'x-api-key': apiKey
      }
    })
    setLogout(dispatch)

    // if (istveLoggedIn) {
    //   flushAllProviders();
    // }
  } catch (err) {
  } finally {
    clearToken()
  }
}

export const appleLogin =
  (appleToken, error = false) =>
    async (dispatch) => {
      if (error) {
        setRequest(dispatch, { err: error })
        return
      }
      const { code, id_token, firstName, lastName, sub, email } = appleToken

      const method = 'POST'
      const url = '/identity/signin/apple'
      const data = {
        AuthenticationCode: code,
        userId: sub,
        email,
        identityToken: id_token,
        firstName,
        lastName
      }
      const additionalParams = {
        deviceName: 'web_browser',
        platform: 'web_browser'
      }

      try {
        const user = await _axios(
          dispatch,
          data,
          method,
          url,
          true,
          true,
          additionalParams
        )

        // setUserDetails(dispatch, user);
        onLogin({ dispatch, user, loginMethod: 'social' })
      } catch (err) {}
    }

export const refreshUser = () => async (dispatch) => {
  const { apiKey, apiProxy, internalName } = config
  resetRequest(dispatch)
  try {
    const token = await getToken()

    const user = await axios({
      url: 'identity/user',
      baseURL: apiProxy,
      method: 'GET',
      params: {
        site: internalName
      },
      headers: {
        Authorization: token,
        'x-api-key': apiKey
      }
    })
    setRequest(dispatch, { status: 'SUCCESS' })
    setUserDetails(dispatch, user.data)
    return user.data
  } catch (err) {
    setRequest(dispatch, { err: err.response?.data })
  } finally {
    setRequest(dispatch, { status: 'PRECALL' })
  }
}

const getUTM = () => {
  const utmCookie = Cookies.get('utm')
  const utmData = utmCookie && JSON.parse(utmCookie)

  return {
    campaign: utmData && utmData.utm_campaign,
    campaign_source: utmData && utmData.utm_source,
    campaign_medium: utmData && utmData.utm_medium,
    utm_params: utmData && utmData.utm_params
  }
}

export const googleLogin = (user) => async (dispatch) => {
  const { internalName, apiKey, apiProxy } = config
  const deviceId = 'browser-' + generateId()

  const loginParams = {
    ...getUTM(),
    // site: 'staging-hoichoitv', //internalName,
    site: internalName,
    device: 'web_browser',
    deviceId
  }

  const { accessToken } = user

  // resetRequest(dispatch);

  try {
    const res = await axios({
      method: 'POST',
      url: `${apiProxy}/identity/signin/google`,
      // baseURL: 'https://staging-api.viewlift.com',
      // url: 'identity/signin/google', // temp to check auth
      params: loginParams,
      data: {
        googleToken: accessToken,
        emailConsent: false
      },
      headers: {
        // Authorization: token,
        // 'x-api-key': 'BkSBbok02k6RYUlCLRzI23wac0euoSfC3FP7uW2S', //staging
        'x-api-key': apiKey
      }
    })

    setRequest(dispatch, { status: 'SUCCESS' })
    onLogin({ dispatch, user: res.data, loginMethod: 'social' })
  } catch (err) {
    setRequest(dispatch, { err: err.response.data })
  } finally {
    setRequest(dispatch, { status: 'PRECALL' })
  }
}

export const facebookLogin = (user) => async (dispatch) => {
  const { internalName, apiKey, apiProxy } = config
  const deviceId = 'browser-' + generateId()

  const loginParams = {
    ...getUTM(),
    site: internalName,
    device: 'web_browser',
    deviceId
  }

  const { accessToken } = user

  // resetRequest(dispatch);

  try {
    const res = await axios({
      method: 'POST',
      url: `${apiProxy}/identity/signin/facebook`,
      // baseURL: 'https://staging-api.viewlift.com',
      // url: 'identity/signin/facebook', // temp to check auth
      params: loginParams,
      data: {
        facebookToken: accessToken,
        emailConsent: false
      },
      headers: {
        // Authorization: token,
        // 'x-api-key': 'BkSBbok02k6RYUlCLRzI23wac0euoSfC3FP7uW2S',
        'x-api-key': apiKey
      }
    })

    setRequest(dispatch, { status: 'SUCCESS' })
    onLogin({ dispatch, user: res.data, loginMethod: 'social' })
  } catch (err) {
    setRequest(dispatch, { err: err.response.data })
  } finally {
    setRequest(dispatch, { status: 'PRECALL' })
  }
}
