import React,{useEffect,useRef} from 'react';
import './style.css';

const index = ({ setCloseInternetPop }) => {

  let timerId = useRef();

  useEffect(()=>{
     timerId.current = setInterval(()=>{
            setCloseInternetPop(true)
      },4000)

      return ()=>{clearInterval(timerId.current)}
  },[])

  const handleClick=(e)=>{
    setCloseInternetPop(true);
  }
  return (
    <div className="internetPop">
      <div className="message">No Internet !</div>
      <div
        className="closeNoInternet"
        onClick={handleClick}
      >x</div>
    </div>
  );
};

export default index;
