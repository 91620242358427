import { SET_NEXT_EPISODE_ID, REMOVE_NEXT_EPISODE_ID } from '../types'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_NEXT_EPISODE_ID:
      return { ...state, ...action.payload }
    case REMOVE_NEXT_EPISODE_ID:
      return {}
    default:
      return state
  }
}
