import { REMOVE_NEXT_EPISODE_ID, SET_NEXT_EPISODE_ID } from '../types'

export const addNextEpisodeId = (id, slideData) => (dispatch) => {
  dispatch({
    type: SET_NEXT_EPISODE_ID,
    payload: { id, slideData }
  })
}

export const removeNextEpisodeId = () => (dispatch) => {
  dispatch({
    type: REMOVE_NEXT_EPISODE_ID
  })
}
