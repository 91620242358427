export const requestUserPermission = () => {
  // if (window.clevertap) {
  window?.clevertap?.notifications?.push?.({
    bodyText:
      'We promise to only send you relevant content and give you updates on your transactions',
    okButtonText: 'Yes, I am in!',
    titleText: 'Hoichoi, Would you like to receive push Notifications?',
    rejectButtonText: 'No Thanks',
    serviceWorkerPath: '/sw.js',
    okButtonColor: '#f4181c'
  })

  // }
}

const notificationsConfig = false
const cleverTapAnalyticsId = '8W6-4W8-8R5Z'

// to initialize the clevertap function

export function initCleverTap () {
  if (cleverTapAnalyticsId) {
    if (notificationsConfig && notificationsConfig.region) {
      window.clevertap = {
        event: [],
        region: notificationsConfig.region,
        profile: [],
        account: [],
        onUserLogin: [],
        notifications: [],
        privacy: []
      }
    } else {
      window.clevertap = {
        event: [],
        profile: [],
        account: [],
        onUserLogin: [],
        notifications: [],
        privacy: []
      }
    }

    // replace with the CLEVERTAP_ACCOUNT_ID with the actual ACCOUNT ID value from your Dashboard -> Settings page
    window.clevertap.account.push({ id: cleverTapAnalyticsId })
    window.clevertap.privacy.push({ optOut: false }) // set the flag to true, if the user of the device opts out of sharing their data
    window.clevertap.privacy.push({ useIP: false }) // set the flag to true, if the user agrees to share their IP data
    const scriptSrc =
      (document.location.protocol === 'https:'
        ? 'https://d2r1yp2w7bby2u.cloudfront.net'
        : 'http://static.clevertap.com') + '/js/clevertap.min.js'
    ;(function (i, s, o, g, a, m) {
      a = s.createElement(o)
      m = s.getElementsByTagName(o)[0]
      a.async = 1
      a.src = g
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', scriptSrc)
    document.addEventListener('CT_web_native_display', function (event) {
      localStorage.setItem('NativeData', JSON.stringify(event?.detail))
      clevertap.renderNotificationViewed(event.detail)
    })
  }
}
// to track the events using clevertap
export function trackCleverTapEvent (eventName, eventData) {
  const clevertap = window.clevertap
  if (clevertap) {
    clevertap.event.push(eventName, eventData)
  }
}

// to add user profile event

export function addUserProfile (profileName, profileData) {
  const clevertap = window.clevertap
  // if(updateProfile & clevertap){
  //   setTimeout(function(){       // Added delay in user profile update to avoid conflicts with user's onUserLogin method
  //     clevertap.profile.push({ [profileName]: profileData })
  //    }, 3000);
  // }
  if (clevertap) {
    setTimeout(function () {
      // Added delay in user profile update to avoid conflicts with user's onUserLogin method
      clevertap.onUserLogin.push({ [profileName]: profileData })
    }, 3000)
  }
}

export const CLEVERTAP_EVENTS = {
  REMOVED_FROM_WATCHLIST: 'REMOVED_FROM_WATCHLIST',
  PLAY_STARTED: 'PLAY_STARTED',
  VIEW_PLANS: 'VIEW_PLANS',
  REGISTRATION_COMPLETE: 'Registration Complete',
  SUBSCRIPTION_COMPLETED: 'SUBSCRIPTION_COMPLETED',
  LOGIN: 'Login',
  ADDED_TO_WATCHLIST: 'Added_To_Watchlist',
  SEARCHED: 'SEARCHED_',
  LOGOUT: 'LOG_OUT',
  WATCHED: 'WATCHED',
  ADD_TO_CART: 'ADD_TO_CART',
  SUBSCRIPTION_FAILED: 'SUBSCRIPTION_FAILED',
  SUBSCRIPTION_INITIATED: 'Subscription_Initiated',
  PAGE_VIEWED: 'PAGE_VIEWED',
  FAILED_SEARCH: 'FAILED_SEARCH',
  REGISTERED_BUT_NOT_SUBSCRIBED: 'Registered But Not Subscribed',
  FILM_VIEWING: 'FILM_VIEWING',
  REGISTRATION: 'Registration',
  SignedUp_: 'SignedUp_',
  Play_Back_Seek_Completed: 'Play_Back_Seek_Completed',
  Rewind_: 'Rewind_',
  Skip_: 'Skip_'
}
