import axios from 'axios'
import * as config from '../../../config'

export const verifyToken = async (data) => {
  const { internalName, apiKey, apiProxy } = config

  try {
    const res = await axios({
      method: 'GET',
      url: `${apiProxy}/identity/user`,
      params: {
        site: internalName
      },

      headers: {
        'x-api-key': apiKey,
        Authorization: data
      }
    })

    if (window.tokenStatus) tokenStatus(true)

    if (window.webkit) {
      window.webkit?.messageHandlers?.onTokenStatus?.postMessage({
        status: true
      })
    }

    return res.data
  } catch (err) {
    // For Android
    if (window.tokenStatus) tokenStatus(false, 'token expired')
    // For ios
    if (window.webkit) {
      window.webkit?.messageHandlers?.onTokenStatus?.postMessage({
        status: false,
        message: 'Token expired'
      })
    }
    console.log(err)
    if (err.message === 'Request failed with status code 401') {
      return err.message
    }
  }
}
