// import _state from '../states';
import {
  GET_PAGE_DATA,
  REMOVE_PAGE_DATA,
  SET_MWEB,
  GET_POPUP_DATA
} from '../types'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PAGE_DATA:
      const _isMWeb = state.isMWeb
      return { ...action.payload, ..._isMWeb }
    case GET_POPUP_DATA:
      return { ...state, popupData: { ...state.popupData, ...action.payload } }
    case SET_MWEB:
      return { ...state, mweb: action.payload }
    case REMOVE_PAGE_DATA:
      return state
    default:
      return state
  }
}
