import React, { useEffect } from 'react'
import { connect } from 'react-redux'
// customs
import { setPopup } from '~/client/redux/actions/popupsActions'
import PlusIcon from '~/client/components/Icons/PlusIcon'
import './styles.css'

function CommonPopup ({
  heading,
  subheading,
  ctaText,
  onCtaClick,
  onCloseClick,
  onOverlayClick,
  videoplayer,
  setPopup
}) {
  const handleClose = () => {
    if (videoplayer) {
      setPopup('videoplayer')
    }
    onCloseClick()
  }
  return (
    <div className='common-popup-container' onClick={onOverlayClick}>
      <div className='common-popup-wrapper'>
        <div className='common-popup-parent'>
          <div
            className='common-popup-close-btn'
            role='button'
            onClick={handleClose}
          >
            <PlusIcon />
          </div>
          <div className='common-popup-heading'>
            {heading || 'Heading 123456789'}
          </div>
          <div className='common-popup-subheading'>
            {subheading ||
              'You are accessing Hoichoi from a region that is not the same as your registered account. Please contact the support team to change your region.'}
          </div>
          <div
            className='common-popup-cta-btn'
            onClick={onCtaClick}
            role='button'
          >
            {ctaText || 'Contact our Support Team'}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(
  (state) => {
    return {
      videoplayer: state?.popups?.videoplayer
    }
  },
  { setPopup }
)(CommonPopup)
